import React, { useMemo } from 'react';
import style from './style.module.less';

const STROKE_WIDTH = 6;
const DEFAULT_SIZE = 120;
interface Props {
  size?: number;
  percentage: number;
}
export const PercentageSpinner: React.FC<Props> = (props) => {
  const { percentage, size = DEFAULT_SIZE } = props;

  const radius = useMemo(() => size / 2 - STROKE_WIDTH / 2, [size]);
  const circumference = useMemo(() => 2 * Math.PI * radius, [radius]);
  //    we must rotate it by 90 degrees. By default circle will start on the right.
  //    Stroke offset effectively rotates the circle.
  const strokeOffset = useMemo(() => (1 / 4) * circumference, [circumference]);
  // Second, calculate dash array. We need dash array containing only two parts -
  //    visible dash, and invisible dash.
  //    Visible dash is how much circumference should be visible.
  //    Hence, we then multiply percentage times the entire circumference.
  const strokeDasharray = useMemo(
    () => (percentage / 100) * circumference,
    [percentage, circumference]
  );

  return (
    <>
      <div
        className={style.loadingSpinner}
        style={{ width: size, height: size }}
      >
        <svg className={style.circleSvg}>
          <circle
            cx={size / 2}
            cy={size / 2}
            r={radius}
            strokeWidth={STROKE_WIDTH}
            className={style.circleBackground}
          />
          <circle
            cx={size / 2}
            cy={size / 2}
            r={radius}
            strokeWidth={STROKE_WIDTH}
            className={style.circle}
            strokeDasharray={`${strokeDasharray}, ${
              circumference - strokeDasharray
            }`}
            strokeDashoffset={strokeOffset}
          />
        </svg>
        <div className={style.text}>
          <span>{percentage}</span>
          <span className={style.percentageText}>%</span>
        </div>
      </div>
    </>
  );
};
