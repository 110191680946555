import { useTranslation } from 'react-i18next';
import { FC, SVGProps, useCallback, useEffect, useState } from 'react';
import BriefcaseIcon from '../assets/icons/briefcase.svg?react';
import CarIcon from '../assets/icons/car.svg?react';
import HomeIcon from '../assets/icons/home.svg?react';
import PaymentIcon from '../assets/icons/payment.svg?react';
import RingIcon from '../assets/icons/ring.svg?react';
import PlaneIcon from '../assets/icons/plane.svg?react';
import GavelIcon from '../assets/icons/gavel.svg?react';
import TreeIcon from '../assets/icons/tree.svg?react';
import PulseIcon from '../assets/icons/pulse.svg?react';
import CopyLink from '../assets/icons/copy-link.svg?react';
import i18next from 'i18next';

export interface HomeExamples {
  title: string;
  description: string;
  icon: FC<SVGProps<SVGSVGElement>>;
}

export const useHomeExamples = (): HomeExamples[] => {
  const { t } = useTranslation();

  const generateExamples = useCallback(() => {
    return [
      {
        title: t('home.explore-examples.workplace.title'),
        description: t('home.explore-examples.workplace.description'),
        icon: BriefcaseIcon,
      },
      {
        title: t('home.explore-examples.property-damage.title'),
        description: t('home.explore-examples.property-damage.description'),
        icon: CarIcon,
      },
      {
        title: t('home.explore-examples.fence.title'),
        description: t('home.explore-examples.fence.description'),
        icon: HomeIcon,
      },
      {
        title: t('home.explore-examples.bankruptcy.title'),
        description: t('home.explore-examples.bankruptcy.description'),
        icon: PaymentIcon,
      },
      {
        title: t('home.explore-examples.divorce.title'),
        description: t('home.explore-examples.divorce.description'),
        icon: RingIcon,
      },
      {
        title: t('home.explore-examples.immigration.title'),
        description: t('home.explore-examples.immigration.description'),
        icon: PlaneIcon,
      },
      {
        title: t('home.explore-examples.real-estate.title'),
        description: t('home.explore-examples.real-estate.description'),
        icon: HomeIcon,
      },
      {
        title: t('home.explore-examples.criminal.title'),
        description: t('home.explore-examples.criminal.description'),
        icon: GavelIcon,
      },
      {
        title: t('home.explore-examples.environment.title'),
        description: t('home.explore-examples.environment.description'),
        icon: TreeIcon,
      },
      {
        title: t('home.explore-examples.business.title'),
        description: t('home.explore-examples.business.description'),
        icon: BriefcaseIcon,
      },
      {
        title: t('home.explore-examples.litigation.title'),
        description: t('home.explore-examples.litigation.description'),
        icon: GavelIcon,
      },
      {
        title: t('home.explore-examples.personal-injury.title'),
        description: t('home.explore-examples.personal-injury.description'),
        icon: PulseIcon,
      },
      {
        title: t('home.explore-examples.rental.title'),
        description: t('home.explore-examples.rental.description'),
        icon: HomeIcon,
      },
      {
        title: t('home.explore-examples.trusts.title'),
        description: t('home.explore-examples.trusts.description'),
        icon: CopyLink,
      },
      {
        title: t('home.explore-examples.patented.title'),
        description: t('home.explore-examples.patented.description'),
        icon: GavelIcon,
      },
      {
        title: t('home.explore-examples.insurance.title'),
        description: t('home.explore-examples.insurance.description'),
        icon: PaymentIcon,
      },
      {
        title: t('home.explore-examples.leaving-ex.title'),
        description: t('home.explore-examples.leaving-ex.description'),
        icon: RingIcon,
      },
      {
        title: t('home.explore-examples.will.title'),
        description: t('home.explore-examples.will.description'),
        icon: CopyLink,
      },
      {
        title: t('home.explore-examples.building.title'),
        description: t('home.explore-examples.building.description'),
        icon: HomeIcon,
      },
      {
        title: t('home.explore-examples.fake-advertising.title'),
        description: t('home.explore-examples.fake-advertising.description'),
        icon: GavelIcon,
      },
    ];
  }, [t]);

  const [examples, setExamples] = useState(generateExamples());

  useEffect(() => {
    i18next.on('languageChanged', () => {
      setExamples(generateExamples());
    });
  }, [generateExamples]);

  return examples;
};
