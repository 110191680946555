import React, { FC, useCallback } from 'react';
import style from './style.module.less';
import whyDesktopImage from '../../../assets/why-panel-bg.jpg';
import { LawyerImage } from '../lawyer-images';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '../../../components/button';

interface Props {
  lawyerImage: LawyerImage;
}

export const HomeBenefitsComponent: FC<Props> = (props) => {
  const { lawyerImage } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();

  const openNewCase = useCallback(
    () => navigate('/chat', { state: { newCase: true } }),
    [navigate]
  );

  return (
    <div className={style.positionWrapper}>
      <div className={style.container}>
        <img
          src={whyDesktopImage}
          alt='Why Law Connect'
          className={style.bgImage}
        />
        <img
          src={lawyerImage.image}
          alt='Lawyer'
          className={style.lawyerImage}
        />
        <div className={style.textContainer}>
          <div className={style.title}>{t('home.benefits-title')}</div>
          <div className={style.benefitsBody}>
            <div className={style.benefitCard}>
              <div className={style.benefitTitle}>
                {t('home.benefits.accessible.title')}
              </div>
              <div className={style.benefitDescription}>
                {t('home.benefits.accessible.description')}
              </div>
            </div>
            <div className={style.benefitCard}>
              <div className={style.benefitTitle}>
                {t('home.benefits.customised.title')}
              </div>
              <div className={style.benefitDescription}>
                {t('home.benefits.customised.description')}
              </div>
            </div>
            <div className={style.benefitCard}>
              <div className={style.benefitTitle}>
                {t('home.benefits.empowered.title')}
              </div>
              <div className={style.benefitDescription}>
                {t('home.benefits.empowered.description')}
              </div>
            </div>
          </div>
          <div className={style.subTitle}>{t('home.ready-to-get-help')}</div>
          <div className={style.buttonContainer}>
            <Button
              label={t('home.ask-question-now')}
              onClick={openNewCase}
              className={style.askQuestionButton}
              iconClassName={style.askQuestionButtonIcon}
              labelClassName={style.askQuestionButtonLabel}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
