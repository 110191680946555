import { LawyerConnection, Message, File as AMFile } from '@law-connect/types';
import React, {
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { COOKIE_EVENT, COOKIE_ID } from '../../components/cookie-consent';
import { useCookies } from 'react-cookie';
import style from './style.module.less';
import { useHandleChatResize } from '../chat/chatbox/use-handle-chat-resize';
import { LawyerIntro } from './lawyer-intro';
import {
  ChatElement,
  ChatElementRef,
} from '../../components/lawyer-chat-element';

interface Props {
  connection: LawyerConnection;
  messages: Message[];
  files: AMFile[];
  isLoading: boolean;
  isSendingFiles: boolean;
  onSendMessage: (args: { text?: string; files?: File[] }) => void;
}

export const ChatWrapper: FC<Props> = (props) => {
  const {
    connection,
    messages,
    isLoading,
    onSendMessage,
    files,
    isSendingFiles,
  } = props;
  const chatRef = useRef<ChatElementRef>(null);
  const placeholderRef = useRef<HTMLDivElement>(null);
  const [textareaHeight, setTextAreaHeight] = useState(null);
  const [isFloating, setIsFloating] = useState(true);
  const [cookieHeight, setCookieHeight] = useState<number>(0);
  const [cookies, setCookie] = useCookies([COOKIE_ID]);

  // if in chat on mobile we want to set the cookie header to not fixed

  // we can use the observer to fetch the height of the textarea (this is a life saver)
  useEffect(() => {
    if (chatRef?.current?.inputRef?.current) {
      const observer = new ResizeObserver((entries) => {
        for (const entry of entries) {
          setTextAreaHeight(entry.borderBoxSize[0].blockSize);
        }
      });
      observer.observe(chatRef.current.inputRef.current);
      // Clean up the observer on component unmount
      return () => {
        observer.disconnect();
      };
    }
  }, []);

  // handle resizing of chat input
  const calcHeightResize = useCallback(
    (noCookie?: boolean) => {
      if (chatRef.current?.chatRef && chatRef.current?.inputRef) {
        const textarea = chatRef.current.inputRef.current;
        const chatBodyRef = chatRef.current.chatRef.current;
        if (textarea) {
          if (chatBodyRef) {
            // we also want to check if the cookie consent is present and if it is we want to make the chat window body smaller
            const cookieConsent = document.getElementById(COOKIE_ID);
            let ch = 0;
            if (cookieConsent?.getBoundingClientRect()?.height && !noCookie) {
              ch = cookieConsent?.getBoundingClientRect()?.height;
            }
            if (ch) {
              setCookieHeight(ch);
            }
            chatBodyRef.style.minHeight = `calc(100dvh - ${
              chatBodyRef.offsetTop
            }px - ${ch}px)`;
          }
        }
      }
    },
    [chatRef]
  );

  useHandleChatResize({
    calcHeightResize,
    files: [],
    message: '',
    height: textareaHeight,
  });  

  const goToBottom = useCallback(() => {
    // scroll position is not pass the chat window then scroll to the bottom
    if (isFloating) {
      chatRef.current?.chatRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
    }
  }, [isFloating]);

  useLayoutEffect(() => {
    goToBottom();
  }, [goToBottom, messages, isLoading, chatRef]);

  useEffect(() => {
    setTimeout(() => goToBottom(), 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // listen for event COOKIE_EVENT
    const handleEvent = () => {
      setCookieHeight(0);
    };
    document.addEventListener(COOKIE_EVENT, handleEvent);
    return () => {
      document.removeEventListener(COOKIE_EVENT, handleEvent);
    };
  }, []);

  return (
    <>
      <ChatElement
        ref={chatRef}
        messages={messages}
        isLoading={isLoading}
        onSendMessage={onSendMessage}
        className={style.chatWrapper}
        bottomOffset={
          cookieHeight
        }
        inputClassName={`${style.chatInput} ${
          isFloating ? style.isFloating : style.notFloating
        }`}
        style={
          {
            '--chat-height': `${textareaHeight}px`,
            '--cookie-height': cookieHeight,
          } as React.CSSProperties
        }
        connection={connection}
        files={files}
        isSendingFiles={isSendingFiles}
      >
        <LawyerIntro />
      </ChatElement>
      <div ref={placeholderRef} className={style.placeholder} />
    </>
  );
};
