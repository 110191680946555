import LeftMaleLawyer1 from '../../assets/lawyers/left-man-1.png';
import LeftMaleLawyer2 from '../../assets/lawyers/left-man-2.png';
import LeftMaleLawyer3 from '../../assets/lawyers/left-man-3.png';
import LeftFemaleLawyer1 from '../../assets/lawyers/left-woman-1.png';
import LeftFemaleLawyer2 from '../../assets/lawyers/left-woman-2.png';
import LeftFemaleLawyer3 from '../../assets/lawyers/left-woman-3.png';
import RightMaleLawyer1 from '../../assets/lawyers/right-man-1.png';
import RightMaleLawyer2 from '../../assets/lawyers/right-man-2.png';
import RightMaleLawyer3 from '../../assets/lawyers/right-man-3.png';
import RightFemaleLawyer1 from '../../assets/lawyers/right-woman-1.png';
import RightFemaleLawyer2 from '../../assets/lawyers/right-woman-2.png';
import RightFemaleLawyer3 from '../../assets/lawyers/right-woman-3.png';

export interface LawyerImage {
  backgroundColor: string;
  image: string;
  left: boolean;
  female?: boolean;
}

export const lawyerImages: LawyerImage[] = [
  {
    image: LeftMaleLawyer1,
    left: true,
    backgroundColor: 'linear-gradient(209deg, #64808D 6.75%, #99B2B7 110.94%)',
  },
  {
    image: RightMaleLawyer1,
    left: false,
    backgroundColor: 'linear-gradient(209deg, #64808D 6.75%, #99B2B7 110.94%)',
  },
  {
    image: LeftMaleLawyer2,
    left: true,
    backgroundColor: 'linear-gradient(209deg, #C19871 6.75%, #DAAB7A 110.94%)',
  },
  {
    image: RightMaleLawyer2,
    left: false,
    backgroundColor: 'linear-gradient(209deg, #C19871 6.75%, #DAAB7A 110.94%)',
  },
  {
    image: LeftMaleLawyer3,
    left: true,
    backgroundColor: 'linear-gradient(209deg, #020E20 6.75%, #3E4C58 110.94%)',
  },
  {
    image: RightMaleLawyer3,
    left: false,
    backgroundColor: 'linear-gradient(209deg, #020E20 6.75%, #3E4C58 110.94%)',
  },
  // female lawyers
  {
    image: LeftFemaleLawyer1,
    left: true,
    backgroundColor: 'linear-gradient(209deg, #4F2023 6.75%, #782F2A 110.94%)',
    female: true,
  },
  {
    image: RightFemaleLawyer1,
    left: false,
    backgroundColor: 'linear-gradient(209deg, #4F2023 6.75%, #782F2A 110.94%)',
    female: true,
  },
  {
    image: LeftFemaleLawyer2,
    left: true,
    backgroundColor: 'linear-gradient(209deg, #426049 6.75%, #728866 110.94%)',
    female: true,
  },
  {
    image: RightFemaleLawyer2,
    left: false,
    backgroundColor: 'linear-gradient(209deg, #426049 6.75%, #728866 110.94%)',
    female: true,
  },
  {
    image: LeftFemaleLawyer3,
    left: true,
    backgroundColor: 'linear-gradient(209deg, #AE7B7A 6.75%, #EBB9AD 110.94%)',
    female: true,
  },
  {
    image: RightFemaleLawyer3,
    left: false,
    backgroundColor: 'linear-gradient(209deg, #AE7B7A 6.75%, #EBB9AD 110.94%)',
    female: true,
  },
];
