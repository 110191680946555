import React, {
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
} from 'react';
import style from './style.module.less';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../components/button';
import { useScreenSize } from '../../../hooks/use-is-mobile';
import { pl } from 'date-fns/locale';

interface Props {
  question: string;
  onChangeQuestion: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  submitQuestion: () => void;
  handleEnter: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
}

export const AskInputComponent: FC<Props> = (props) => {
  const { t } = useTranslation();
  const { question, onChangeQuestion, submitQuestion, handleEnter } = props;
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const placeholderRef = useRef<HTMLDivElement>(null);

  const resize = useCallback(() => {
    if (textareaRef.current) {
      textareaRef.current.style.minHeight = '0px';
      const scrollHeight = textareaRef.current.scrollHeight;
      textareaRef.current.style.minHeight = scrollHeight + 'px';
      placeholderRef.current.style.minHeight = scrollHeight + 'px';
    }
  }, [textareaRef]);

  useLayoutEffect(() => {
    resize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question, textareaRef]);

  useEffect(() => {
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.innerWidth]);

  return (
    <div className={style.askInputContainer}>
      <div
        ref={placeholderRef}
        className={style.placeholder}
        style={{ opacity: question ? 0 : 1 }}
        dangerouslySetInnerHTML={{ __html: t('home.ask-placeholder') }}
      />
      <textarea
        placeholder={''}
        className={style.askInput}
        ref={textareaRef}
        value={question}
        onChange={onChangeQuestion}
        onKeyDown={handleEnter}
      />
      <Button
        label={null}
        className={style.askButton}
        labelClassName={style.buttonLabel}
        iconClassName={style.buttonIcon}
        onClick={submitQuestion}
      />
    </div>
  );
};
