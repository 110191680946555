import React, {
  createRef,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import style from './style.module.less';

import EyeIcon from '../../../assets/icons/eye.svg?react';
import DataIcon from '../../../assets/icons/data.svg?react';
import PadlockIcon from '../../../assets/icons/padlock-closed.svg?react';
import ShieldIcon from '../../../assets/icons/shield-tick.svg?react';
import { useScreenSize, WidthType } from '../../../hooks/use-is-mobile';
import LeftArrowIcon from '../../../assets/icons/arrow-left.svg?react';
import RightArrowIcon from '../../../assets/icons/arrow-right.svg?react';

const CARDS_DATA = [
  {
    Icon: EyeIcon,
    title: 'home.security-list.privacy.title',
    description: 'home.security-list.privacy.description',
  },
  {
    Icon: DataIcon,
    title: 'home.security-list.safeguard.title',
    description: 'home.security-list.safeguard.description',
  },
  {
    Icon: PadlockIcon,
    title: 'home.security-list.login.title',
    description: 'home.security-list.login.description',
  },
  {
    Icon: ShieldIcon,
    title: 'home.security-list.advanced.title',
    description: 'home.security-list.advanced.description',
  },
];

enum CarouselColumns {
  LargeDesktop = 4,
  SmallDesktop = 3,
  Tablet = 2,
  Mobile = 1,
}

export const HomeSecurityComponent: FC = () => {
  const { t } = useTranslation();
  const screenType = useScreenSize();
  const [activeIndex, setActiveIndex] = useState(0);
  const [newActive, setNewActive] = useState<number | null>(null);
  const ref = createRef<HTMLDivElement>();
  // large desktop we ignore, small desktop is 3 columns, tablet is 2 columns, mobile is 1 column
  const handleResize = useCallback(() => {
    // on resize if the active index is greater than the number of items, set it to 0
    if (screenType === WidthType.LargeDesktop) {
      setActiveIndex(0);
    } else if (screenType === WidthType.SmallDesktop) {
      if (activeIndex > CARDS_DATA.length - CarouselColumns.SmallDesktop) {
        setActiveIndex(0);
      }
    } else if (screenType === WidthType.Tablet) {
      if (activeIndex > CARDS_DATA.length - CarouselColumns.Tablet) {
        setActiveIndex(0);
      }
    }
  }, [activeIndex, screenType]);

  useEffect(() => {
    handleResize();
  }, [handleResize, screenType]);

  const clickPrevious = useCallback(() => {
    if (activeIndex > 0) {
      setNewActive(activeIndex - 1);
    }
  }, [activeIndex, setNewActive]);

  const clickNext = useCallback(() => {
    if (screenType === WidthType.SmallDesktop) {
      if (activeIndex < CARDS_DATA.length - CarouselColumns.SmallDesktop) {
        setNewActive(activeIndex + 1);
      }
    } else if (screenType === WidthType.Tablet) {
      if (activeIndex < CARDS_DATA.length - CarouselColumns.Tablet) {
        setNewActive(activeIndex + 1);
      }
    }
  }, [activeIndex, screenType, setNewActive]);

  const nextButtonDisabled = useMemo(() => {
    if (screenType === WidthType.SmallDesktop) {
      return activeIndex >= CARDS_DATA.length - CarouselColumns.SmallDesktop;
    } else if (screenType === WidthType.Tablet) {
      return activeIndex >= CARDS_DATA.length - CarouselColumns.Tablet;
    }
    return false;
  }, [activeIndex, screenType]);

  // on scroll we want to update the index of the active card
  // so that scrolling and button press do not conflict we use newActive
  const updateIndex = useCallback(() => {
    if (newActive == null) {
      if (ref.current) {
        const index = Math.floor(
          ref.current.scrollLeft / ref.current.children[0].clientWidth
        );
        setActiveIndex(index);
      }
    }
  }, [newActive, ref]);

  useEffect(() => {
    if (ref.current && newActive !== null) {
      // find child element by index
      const firstElement = ref.current.children[newActive];
      if (firstElement) {
        ref.current.scrollTo({
          left: newActive * (firstElement.clientWidth + 40),
          behavior: 'smooth',
        });
      }
      setActiveIndex(newActive);
      setNewActive(null);
    }
  }, [newActive, activeIndex, ref]);

  return (
    <div className={style.homeSecurityContainer}>
      <div className={style.titleContainer}>
        <div className={style.title}>{t('home.security-title')}</div>
        <div
          className={style.titleDescription}
          dangerouslySetInnerHTML={{ __html: t('home.security-description') }}
        />
      </div>

      <div className={style.securityCarousel} ref={ref} onScroll={updateIndex}>
        {CARDS_DATA.map((card, index) => (
          <div className={style.carouselCard} key={index}>
            <card.Icon className={style.carouselIcon} />
            <div className={style.carouselTitle}>{t(card.title)}</div>
            <div className={style.carouselDescription}>
              {t(card.description)}
            </div>
          </div>
        ))}
      </div>
      <div className={style.carouselDesktopControls}>
        <button
          onClick={clickPrevious}
          className={`${style.carouselButton} ${
            activeIndex === 0 ? style.disabled : ''
          }`}
        >
          <LeftArrowIcon className={style.arrowIcon} />
        </button>
        <button
          onClick={clickNext}
          className={`${style.carouselButton} ${
            nextButtonDisabled ? style.disabled : ''
          }`}
        >
          <RightArrowIcon className={style.arrowIcon} />
        </button>
      </div>
      <div className={style.carouselMobileControls}>
        {CARDS_DATA.map((_, index) => (
          <div
            className={`${style.carouselDot} ${
              index === activeIndex ? style.active : ''
            }`}
            key={index}
          />
        ))}
      </div>
    </div>
  );
};
