import React from 'react';
import { useTranslation } from 'react-i18next';
import { Header } from '../../components/header';
import { Footer } from '../../components/footer';
import {
  NarrowWrapper,
  WrapperOffsetSide,
} from '../../components/narrow-wrapper';
import { Link } from 'react-router-dom';
import style from './style.module.less';
import { PageTitleHero } from '../../components/page-title-hero';
import { ContentParagraphs } from '../../components/content-paragraphs';
import { CONTACT_US_LINK, INFO_LINK } from '../../constants';
import { Helmet } from 'react-helmet';
import { ContentWrapper } from '../../components/content-wrapper';

interface Props { }
export const Privacy: React.FC<Props> = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('pages.privacy-policy')}</title>
      </Helmet>
      <Header />
      <PageTitleHero title={t('privacy.title')} />
      <ContentWrapper className={style.contentBackground}>
        <div className={style.content}>

          <div className={style.section}>
            <ContentParagraphs className={style.paragraph}>
              <p>
                {t('privacy.description.0')}
              </p>
            </ContentParagraphs>
          </div>

          <div className={style.section}>
            <ContentParagraphs className={style.paragraph}>
              <p>{t('privacy.description.1')}</p>
            </ContentParagraphs>
          </div>


          <div className={style.section}>
            <h1>{t('privacy.1.title')}</h1>
            <h2>1. {t('privacy.1.subsections.0.title')}</h2>
            <ContentParagraphs className={style.paragraph}>
              <p>
                {t('privacy.1.subsections.0.body.0')}
                <ol>
                  <li>{t('privacy.1.subsections.0.body.1')}</li>
                  <li>{t('privacy.1.subsections.0.body.2')}</li>
                  <li>{t('privacy.1.subsections.0.body.3')}</li>
                  <li>{t('privacy.1.subsections.0.body.4')}</li>
                  <li>{t('privacy.1.subsections.0.body.5')}</li>
                  <li>{t('privacy.1.subsections.0.body.6')}</li>
                  <li>{t('privacy.1.subsections.0.body.7')}</li>
                </ol>
              </p>
            </ContentParagraphs>

            <p>&nbsp;</p>
            <h2>2. {t('privacy.1.subsections.1.title')}</h2>
            <ContentParagraphs className={style.paragraph}>
              <p>
                {t('privacy.1.subsections.1.body.0')}
                <ul>
                  <li>{t('privacy.1.subsections.1.body.1')}</li>
                  <li>{t('privacy.1.subsections.1.body.2')}</li>
                </ul>
              </p>
            </ContentParagraphs>

            <p>&nbsp;</p>
            <h2>3. {t('privacy.1.subsections.2.title')}</h2>
            <ContentParagraphs className={style.paragraph}>
              <p>
                {t('privacy.1.subsections.2.body')}
              </p>
            </ContentParagraphs>

            <p>&nbsp;</p>
            <h2>4. {t('privacy.1.subsections.3.title')}</h2>
            <ContentParagraphs className={style.paragraph}>
              <p>
                {t('privacy.1.subsections.3.body')}
              </p>
            </ContentParagraphs>

            <p>&nbsp;</p>
            <h2>5. {t('privacy.1.subsections.4.title')}</h2>
            <ContentParagraphs className={style.paragraph}>
              <p>
                {t('privacy.1.subsections.4.body.0')}
                <ul>
                  <li>{t('privacy.1.subsections.4.body.1')}</li>
                  <li>{t('privacy.1.subsections.4.body.2')}</li>
                  <li>{t('privacy.1.subsections.4.body.3')}</li>
                  <li>{t('privacy.1.subsections.4.body.4')}</li>
                  <li>{t('privacy.1.subsections.4.body.5')}</li>
                  <li>{t('privacy.1.subsections.4.body.6')}</li>
                  <li>{t('privacy.1.subsections.4.body.7')}</li>
                  <li>{t('privacy.1.subsections.4.body.8')}</li>
                </ul>
              </p>
              <p>
                {t('privacy.1.subsections.4.body.9')}
                <ul>
                  <li>{t('privacy.1.subsections.4.body.10')}</li>
                  <li>{t('privacy.1.subsections.4.body.11')}</li>
                  <li>{t('privacy.1.subsections.4.body.12')}</li>
                </ul>
              </p>
              <p>
                {t('privacy.1.subsections.4.body.13')}
              </p>
            </ContentParagraphs>

            <p>&nbsp;</p>
            <h2>6. {t('privacy.1.subsections.5.title')}</h2>
            <ContentParagraphs className={style.paragraph}>
              <p>
                {t('privacy.1.subsections.5.body.0')}
              </p>
              <p>
                {t('privacy.1.subsections.5.body.1')}
                <ul>
                  <li>{t('privacy.1.subsections.5.body.2')}</li>
                  <li>{t('privacy.1.subsections.5.body.3')}</li>
                  <li>{t('privacy.1.subsections.5.body.4')}</li>
                  <li>{t('privacy.1.subsections.5.body.5')}</li>
                  <li>{t('privacy.1.subsections.5.body.6')}</li>
                  <li>{t('privacy.1.subsections.5.body.7')}</li>
                  <li>{t('privacy.1.subsections.5.body.8')}</li>
                </ul>
              </p>
              <p>
                {t('privacy.1.subsections.5.body.9')}
              </p>
              <p>
                {t('privacy.1.subsections.5.body.10')}
              </p>
            </ContentParagraphs>


            <p>&nbsp;</p>
            <h2>7. {t('privacy.1.subsections.6.title')}</h2>
            <ContentParagraphs className={style.paragraph}>
              <p>
                <ul>
                  <li>{t('privacy.1.subsections.6.body.0')}</li>
                  <li>{t('privacy.1.subsections.6.body.1')}</li>
                  <li>{t('privacy.1.subsections.6.body.2')}</li>
                </ul>
              </p>
            </ContentParagraphs>


          </div>

          <div className={style.section}>
            <h1>{t('privacy.2.title')}</h1>
            <ContentParagraphs className={style.paragraph}>
              <p>{t('privacy.2.body')}</p>
            </ContentParagraphs>
          </div>

          <div className={style.section}>
            <h1>{t('privacy.3.title')}</h1>

            <h2>{t('privacy.3.subsections.0.title')}</h2>
            <ContentParagraphs className={style.paragraph}>
              <p>
                {t('privacy.3.subsections.0.body')}
              </p>
            </ContentParagraphs>

            <p>&nbsp;</p>
            <h2>{t('privacy.3.subsections.1.title')}</h2>
            <ContentParagraphs className={style.paragraph}>
              <p>
                {t('privacy.3.subsections.1.body.0')}
                <ul>
                  <li>{t('privacy.3.subsections.1.body.1')}</li>
                  <li>{t('privacy.3.subsections.1.body.2')}</li>
                  <li>{t('privacy.3.subsections.1.body.3')}</li>
                  <li>{t('privacy.3.subsections.1.body.4')}</li>
                  <li>{t('privacy.3.subsections.1.body.5')}</li>
                </ul>
              </p>
              <p>
                {t('privacy.3.subsections.1.body.6')}
              </p>
              <p>
                {t('privacy.3.subsections.1.body.7')}
              </p>
              <p>
                {t('privacy.3.subsections.1.body.8')}
              </p>
            </ContentParagraphs>

            <p>&nbsp;</p>
            <h2>{t('privacy.3.subsections.2.title')}</h2>
            <ContentParagraphs className={style.paragraph}>
              <p>
                {t('privacy.3.subsections.2.body.0')}
              </p>
              <p>
                {t('privacy.3.subsections.2.body.1')}
                <ul>
                  <li>{t('privacy.3.subsections.2.body.1')}</li>
                  <li>{t('privacy.3.subsections.2.body.2')}</li>
                  <li>{t('privacy.3.subsections.2.body.3')}</li>
                  <li>{t('privacy.3.subsections.2.body.4')}</li>
                  <li>{t('privacy.3.subsections.2.body.5')}</li>
                  <li>{t('privacy.3.subsections.2.body.6')}</li>
                </ul>
              </p>
              <p>
                {t('privacy.3.subsections.2.body.7')}
              </p>
            </ContentParagraphs>

            <p>&nbsp;</p>
            <h2>{t('privacy.3.subsections.3.title')}</h2>
            <ContentParagraphs className={style.paragraph}>
              <p>{t('privacy.3.subsections.3.body.0')}</p>
              <p>{t('privacy.3.subsections.3.body.1')}</p>
              <p>{t('privacy.3.subsections.3.body.2')}</p>
              <p>{t('privacy.3.subsections.3.body.3')}</p>
              <p>{t('privacy.3.subsections.3.body.4')}</p>
            </ContentParagraphs>

            <p>&nbsp;</p>
            <h2>{t('privacy.3.subsections.4.title')}</h2>
            <ContentParagraphs className={style.paragraph}>
              <p>{t('privacy.3.subsections.4.body')}</p>
            </ContentParagraphs>

            <p>&nbsp;</p>
            <h2>{t('privacy.3.subsections.5.title')}</h2>
            <ContentParagraphs className={style.paragraph}>
              <p>{t('privacy.3.subsections.5.body')}</p>
            </ContentParagraphs>

            <p>&nbsp;</p>
            <h2>{t('privacy.3.subsections.6.title')}</h2>
            <ContentParagraphs className={style.paragraph}>
              <p>
                {t('privacy.3.subsections.6.body.0')}
                <ul>
                  <li>{t('privacy.3.subsections.6.body.1')}</li>
                  <li>{t('privacy.3.subsections.6.body.2')}</li>
                  <li>{t('privacy.3.subsections.6.body.3')}</li>
                  <li>{t('privacy.3.subsections.6.body.4')}</li>
                  <li>{t('privacy.3.subsections.6.body.5')}</li>
                  <li>{t('privacy.3.subsections.6.body.6')}</li>
                  <li>{t('privacy.3.subsections.6.body.7')}</li>
                  <li>{t('privacy.3.subsections.6.body.8')}</li>
                  <li>{t('privacy.3.subsections.6.body.9')}</li>
                </ul>
              </p>
            </ContentParagraphs>
          </div>


          <div className={style.section}>
            <h1>{t('privacy.4.title')}</h1>
            <ContentParagraphs className={style.paragraph}>
              <p>{t('privacy.4.body')}</p>
            </ContentParagraphs>
          </div>

          <div className={style.section}>
            <h1>{t('privacy.5.title')}</h1>
            <ContentParagraphs className={style.paragraph}>
              <p>{t('privacy.5.body.0')}</p>
              <p>{t('privacy.5.body.1')}</p>
            </ContentParagraphs>
          </div>

          <div className={style.section}>
            <h1>{t('privacy.6.title')}</h1>
            <ContentParagraphs className={style.paragraph}>
              <p>{t('privacy.6.body')}</p>
            </ContentParagraphs>
          </div>

          <div className={style.section}>
            <h1>{t('privacy.contact.title')}</h1>
            <ContentParagraphs className={style.paragraph}>
              <p
                className={style.contact}
                dangerouslySetInnerHTML={{
                  __html: t('privacy.contact.body').replace(
                    'CONTACT_LINK',
                    CONTACT_US_LINK
                  ),
                }}
              />
            </ContentParagraphs>
          </div>
        </div>
      </ContentWrapper>
      <Footer />
    </>
  );
};
