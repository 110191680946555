import React from 'react';
import style from './style.module.less';

interface Props {
  title: string;
  className?: string;
}

export const ButtonTitle: React.FC<Props> = (props) => {
  const { title, className } = props;
  return (
    <div className={`${style.buttonTitle} ${className ?? ''}`}>{title}</div>
  );
};
