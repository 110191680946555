/* eslint-disable @stylistic/js/max-len */
import React, {
  FC,
  useEffect,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import styles from './style.module.less';
import env from '../../../constants/env';
import ChatIcon from '../../../assets/icons/chat.svg?react';
import VerifyShieldIcon from '../../../assets/icons/verify-shield.svg?react';
import ConnectIcon from '../../../assets/icons/connect.svg?react';
import { Button } from '../../../components/button';
import { useNavigate } from 'react-router-dom';

const EMAIL = `chat@${env.EMAIL_DOMAIN}`;

export const HomeHowItWorks: FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const emailUrl = useMemo(() => {
    return `mailto:${EMAIL}?subject=Legal question`;
  }, []);

  useEffect(() => {
    const button = document.getElementById('email-button');
    if (button) {
      button.classList.add(styles.linkButton);
      button.onclick = () => {
        window.location.href = emailUrl;
      };
    }
  }, [emailUrl]);

  const getStarted = () => {
    navigate('/chat', {
      state: { newCase: true, message: undefined },
    });
  };

  return (
    <>
      <div className={styles.howItWorks}>
        <div className={styles.intro}>
          <div className={styles.introTitle}>
            {t('home.how-it-works-title')}
          </div>
          <div className={styles.introDescription}>
            {t('home.how-it-works-description')}
          </div>
        </div>

        <div className={styles.steps}>
          <div className={styles.step}>
            <div className={styles.stepHeader}>
              <div className={styles.stepIcon}>
                <ChatIcon className={styles.icon} />
              </div>
              <div className={styles.titleWrapper}>
                <div className={styles.subTitle}>{t('home.how-it-works.step1.subtitle')}</div>
                <div className={styles.title}>{t('home.how-it-works.step1.title')}</div>
              </div>
            </div>
            <div className={styles.stepBody} dangerouslySetInnerHTML={{ __html: t('home.how-it-works.step1.description') }} />
          </div>
          <div className={styles.step}>
            <div className={styles.stepHeader}>
              <div className={styles.stepIcon}>
                <VerifyShieldIcon className={styles.icon} />
              </div>
              <div className={styles.titleWrapper}>
                <div className={styles.subTitle}>{t('home.how-it-works.step2.subtitle')}</div>
                <div className={styles.title}>{t('home.how-it-works.step2.title')}</div>
              </div>
            </div>
            <div className={styles.stepBody}>
              {t('home.how-it-works.step2.description')}
            </div>
          </div>
          <div className={styles.step}>
            <div className={styles.stepHeader}>
              <div className={styles.stepIcon}>
                <ConnectIcon className={styles.icon} />
              </div>
              <div className={styles.titleWrapper}>
                <div className={styles.subTitle}>{t('home.how-it-works.step3.subtitle')}</div>
                <div className={styles.title}>{t('home.how-it-works.step3.title')}</div>
              </div>
            </div>
            <div className={styles.stepBody}>
              {t('home.how-it-works.step3.description')}
            </div>
          </div>
        </div>

        <div className={styles.getStarted}>
          <div className={styles.getStartedTitle}>
            {t('home.how-it-works.get-started-title')}
          </div>
          <Button label={t('home.how-it-works.get-started-button')} className={styles.getStartedButton} onClick={getStarted} />
        </div>
      </div>
    </>
  );
};
