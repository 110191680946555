import React from 'react';
import { useTranslation } from 'react-i18next';
import { Wrapper } from '../../components/wrapper';
import { Header } from '../../components/header';
import { Footer } from '../../components/footer';
import style from './style.module.less';
import { AboutCardComponent } from '../../components/about-card';
import { Helmet } from 'react-helmet';
import { useScreenSize, WidthType } from '../../hooks/use-is-mobile';

import HomeBgDesktop from '../../assets/home-bg-desktop.jpg';
import HomeBgMobile from '../../assets/home-bg-mobile.jpg';

interface Props {}
export const About: React.FC<Props> = () => {
  const { t } = useTranslation();
  const screenType = useScreenSize();

  const Image = screenType === WidthType.Mobile ? HomeBgMobile : HomeBgDesktop;
  return (
    <>
      <Helmet>
        <title>{t('pages.about')}</title>
      </Helmet>
      <Header />
      <Wrapper className={style.container} contentClassName={style.content}>
        <div className={style.heroCard}>
          <div className={style.titleWrapper}>
            <div className={style.title}>{t('about.title')}</div>
          </div>
          <div className={style.heroImageWrapper}>
            <img src={Image} alt='scale' className={style.heroImage} />
          </div>
        </div>
        <div>
          <AboutCardComponent
            iconTitle={t('about.mission')}
            title={t('about.mission-title')}
            descriptions={[{ context: t('about.mission-description') }]}
            image={'/about-brief.png'}
          />
          <AboutCardComponent
            iconTitle={t('about.how')}
            title={t('about.how-title')}
            descriptions={[
              {
                title: t('about.accessibility'),
                context: t('about.accessibility-description'),
              },
              {
                title: t('about.empowering'),
                context: t('about.empowering-description'),
              },
              {
                title: t('about.transformative'),
                context: t('about.transformative-description'),
              },
            ]}
            right={true}
            image={'/about-hammer.png'}
          />
          <AboutCardComponent
            iconTitle={t('about.values')}
            title={t('about.values-title')}
            descriptions={[
              {
                title: t('about.innovation'),
                context: t('about.innovation-description'),
              },
              {
                title: t('about.quality'),
                context: t('about.quality-description'),
              },
              {
                title: t('about.inclusivity'),
                context: t('about.inclusivity-description'),
              },
            ]}
            right={false}
            image={'/about-paper.png'}
          />
        </div>
      </Wrapper>
      <Footer />
    </>
  );
};
