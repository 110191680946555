import React, { CSSProperties, FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import style from './style.module.less';
import { Button } from '../../../components/button';
import { LawyerImage } from '../lawyer-images';
import BriefcaseIcon from '../../../assets/icons/briefcase.svg?react';
import { useKindeAuth } from '@kinde-oss/kinde-auth-react';
import { useNavigate } from 'react-router-dom';
import { useScreenSize, WidthType } from '../../../hooks/use-is-mobile';

interface Props {
  lawyerImage: LawyerImage;
}

export const RegisterAccountComponent: FC<Props> = (props) => {
  const { lawyerImage } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const screenType = useScreenSize();
  const { register, isAuthenticated } = useKindeAuth();

  const onActionClick = useCallback(() => {
    if (isAuthenticated) {
      navigate('/chat', {
        state: { newCase: true, message: undefined },
      });
    } else {
      register();
    }
  }, [isAuthenticated, navigate, register]);

  const cardImage = useMemo(
    () => (
      <div
        className={style.emailCardImageWrapper}
        style={
          {
            '--lawyer-background': lawyerImage.backgroundColor,
          } as CSSProperties
        }
      >
        <BriefcaseIcon className={style.emailCardImage} />
      </div>
    ),
    [lawyerImage.backgroundColor]
  );

  return (
    <div className={`${style.descriptionCard} ${style.right}`}>
      {screenType === WidthType.Mobile ? cardImage : null}
      <div className={style.bodyContainer}>
        <div className={style.title}>
          {isAuthenticated
            ? t('home.start-new-case')
            : t('home.register-account')}
        </div>
        <div
          className={style.body}
          dangerouslySetInnerHTML={{
            __html: isAuthenticated
              ? t('home.start-new-case-description')
              : t('home.register-account-description'),
          }}
        />
        <div className={style.buttonContainer}>
          <Button
            label={isAuthenticated ? t('home.start-a-case') : t('home.sign-up')}
            className={style.emailUsButton}
            labelClassName={style.buttonLabel}
            iconClassName={style.buttonIcon}
            onClick={onActionClick}
          />
          <div className={style.placeholderButton}/>
        </div>
      </div>
      {screenType === WidthType.Mobile ? null : cardImage}
    </div>
  );
};
