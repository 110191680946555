import React, { createRef } from 'react';
import style from './style.module.less';
import { NavLink } from 'react-router-dom';
import LogoImage from '../../assets/logo-blue.png';
import { useTranslation } from 'react-i18next';
import { CONTACT_US_LINK, SOCIAL_MEDIA_LINKS } from '../../constants';
import { useKindeAuth } from '@kinde-oss/kinde-auth-react';
import AuthModal, { LoginModalRef } from '../auth-modal';

interface Props {
  className?: string;
}

export const Footer: React.FC<Props> = (props) => {
  const { className = '' } = props;
  const { isAuthenticated } = useKindeAuth();
  const { t } = useTranslation();
  const authModalRef = createRef<LoginModalRef>();

  return (
    <>
      <div className={`${style.footer} ${className}`}>
        <div className={style.content}>
          <div className={style.logoColumn}>
            <div className={style.logo}>
              <img src={LogoImage} alt='AskMaxwell' />
            </div>
          </div>
          <div className={style.column}>
            <div className={`${style.column} ${style.mainMenu}`}>
              <NavLink
                to='/'
                className={({ isActive }) =>
                  `${style.menuItem} ${isActive ? style.active : ''}`
                }
              >
                {t('pages.home')}
              </NavLink>

              <NavLink
                to='/about'
                className={({ isActive }) =>
                  `${style.menuItem} ${isActive ? style.active : ''}`
                }
              >
                {t('pages.about')}
              </NavLink>
              <NavLink
                to='/account'
                className={({ isActive }) =>
                  `${style.menuItem} ${isActive ? style.active : ''}`
                }
                onClick={(e) => {
                  if (!isAuthenticated) {
                    e.preventDefault();
                    authModalRef.current?.openModal();
                  }
                }}
              >
                {t('pages.account')}
              </NavLink>
            </div>
          </div>
          <div className={`${style.column} ${style.subMenu}`}>
            <NavLink
              to='/terms'
              className={({ isActive }) =>
                `${style.menuItem} ${isActive ? style.active : ''}`
              }
            >
              {t('pages.terms')}
            </NavLink>
            <NavLink
              to='/privacy'
              className={({ isActive }) =>
                `${style.menuItem} ${isActive ? style.active : ''}`
              }
            >
              {t('pages.privacy-policy')}
            </NavLink>
            <a href={CONTACT_US_LINK} className={style.menuItem}>
              {t('pages.contact-us')}
            </a>
          </div>
          <div className={style.info}>
            <div className={style.address}>
              Level 8, 207 Kent Street
              <br />
              Sydney, NSW
              <br />
              Australia, 2000
              <br />
            </div>
          </div>
        </div>
        <div className={style.bottomBarContainer}>
          <div className={style.copyright}>
            {t('auth.copyright')}
            <br />
            {t('auth.copyright-description')}
          </div>
          <div className={style.socialContainer}>
            {SOCIAL_MEDIA_LINKS.map((link, index) => (
              <a
                key={index}
                href={link.link}
                target='_blank'
                rel='noreferrer'
                className={style.socialIcon}
              >
                <link.icon className={style.socialIcon} />
              </a>
            ))}
          </div>
        </div>
      </div>
      <AuthModal ref={authModalRef} />
    </>
  );
};
