import React, { FC } from 'react';
import {
  LawyerConnection,
  Message,
  MessageType,
  File,
} from '@law-connect/types';
import {
  ChatMessageType,
  ChatMessageWrapper,
  formatLongDateTime,
} from '@law-connect/react-components';
import { PermissionMessage } from './permission-message';
import { ClientInductionMessage } from './client-induction';
import style from './style.module.less';
import placeholderImage from '../../assets/profile-no-img.png';

interface MessageWrapperProps {
  message: Message;
  messages: Message[];
  files: File[];
  hoverDelay?: number;
  connection: LawyerConnection;
}

export const MessageWrapper: FC<MessageWrapperProps> = (props) => {
  const { message, messages, connection, files } = props;
  const hoverDelay = props.hoverDelay ?? 200;

  if (
    message.type === MessageType.Permission ||
    message.type === MessageType.ClientIntakeForm
  ) {
    const MessageComponent =
      message.type === MessageType.Permission
        ? PermissionMessage
        : ClientInductionMessage;
    return (
      <MessageComponent
        messages={messages}
        message={message}
        connection={connection}
      />
    );
  } else if (
    message.type === MessageType.Chat ||
    message.type === MessageType.Location ||
    message.type === MessageType.Confirmation
  ) {
    if (message.content) {
      return (
        <ChatMessageWrapper
          messageBody={message.content}
          type={
            message.from === 'lawyer'
              ? ChatMessageType.Receiver
              : ChatMessageType.Sender
          }
          timestamp={
            message.createdAt ? formatLongDateTime(message.createdAt) : null
          }
          avatar={
            message.from === 'lawyer' ? (
              <img
                src={connection.lawyer.image}
                alt={connection.lawyer.firstName}
                className={style.avatarIcon}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = placeholderImage;
                }}
              />
            ) : null
          }
          avatarClassName={style.avatarIconContainer}
          hoverDelay={hoverDelay}
          className={`${style.messageWrapper} ${
            message.from === 'lawyer'
              ? style.noBottomBorderRadiusReceiver
              : style.noBottomBorderRadiusSender
          }`}
        />
      );
    } else if (message.fileIds?.length) {
      const messageFiles = message.fileIds.map((fileId) =>
        files.find((f) => f.id === fileId)
      );
      return (
        <ChatMessageWrapper
          key={message.id}
          messageBody={message.content || ''}
          type={
            message.from === 'lawyer'
              ? ChatMessageType.Receiver
              : ChatMessageType.Sender
          }
          timestamp={
            message.createdAt ? formatLongDateTime(message.createdAt) : null
          }
          files={messageFiles}
          fileClassName={
            message.from === 'lawyer'
              ? style.lawyerFileName
              : style.clientFileName
          }
          filePreviewClassName={
            message.from === 'lawyer'
              ? style.lawyerFilePreview
              : style.clientFilePreview
          }
          className={`${style.messageWrapper} ${
            message.from === 'lawyer'
              ? style.noBottomBorderRadiusReceiver
              : style.noBottomBorderRadiusSender
          }`}
          avatar={
            message.from === 'lawyer' ? (
              <img
                src={connection.lawyer.image}
                alt={connection.lawyer.firstName}
                className={style.avatarIcon}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = placeholderImage;
                }}
              />
            ) : null
          }
          avatarClassName={style.avatarIconContainer}
          fixedPositioning={true}
        />
      );
    }
  }
};
