import React, {
  ChangeEvent,
  useCallback,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useScreenSize, WidthType } from '../../../hooks/use-is-mobile';
import style from './style.module.less';
import { AskInputComponent } from './ask-input';

import HomeBgDesktop from '../../../assets/home-bg-desktop.jpg';
import HomeBgMobile from '../../../assets/home-bg-mobile.jpg';
import { LawyerImage } from '../lawyer-images';

interface Props {
  lawyerLeft: LawyerImage;
  lawyerRight: LawyerImage;
}

export const HomeHero: React.FC<Props> = (props) => {
  const { lawyerLeft, lawyerRight } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const screenType = useScreenSize();
  const [question, setQuestion] = useState('');

  // we want to navigate to the case using this variable to control after deleteSession is called

  const onCaseClick = useCallback(
    (description?: string | null) => {
      navigate('/chat', {
        state: { newCase: true, message: description ?? undefined },
      });
    },
    [navigate]
  );

  const onChangeQuestion = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      setQuestion(e.target.value);
    },
    []
  );

  const submitQuestion = useCallback(() => {
    if (question.length) {
      onCaseClick(question);
    } else {
      onCaseClick();
    }
  }, [onCaseClick, question]);

  const handleEnter = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        submitQuestion();
      }
    },
    [submitQuestion]
  );
  // const isDesktop =
  //   screenType === WidthType.LargeDesktop ||
  //   screenType === WidthType.SmallDesktop;

  const Image = screenType === WidthType.Mobile ? HomeBgMobile : HomeBgDesktop;

  return (
    <>
      <div className={style.heroCard}>
        <div className={style.titleWrapper}>
          <div className={style.title}>{t('home.title1')}</div>
        </div>
        <div className={style.heroImageWrapper}>
          <img src={Image} alt='scale' className={style.heroImage} />
        </div>
        <div className={style.lawyerImagesContainer}>
          <div className={style.lawyerImageLeftContainer}>
            <img
              src={lawyerLeft.image}
              alt='lawyer'
              className={style.lawyerImage}
            />
          </div>
          <div className={style.lawyerImageRightContainer}>
            <img
              src={lawyerRight.image}
              alt='lawyer'
              className={style.lawyerImage}
            />
          </div>
        </div>
        {/* {isDesktop && ( */}
        <AskInputComponent
          question={question}
          onChangeQuestion={onChangeQuestion}
          submitQuestion={submitQuestion}
          handleEnter={handleEnter}
        />
        {/* )} */}
      </div>

      {/* {!isDesktop && (
        <AskInputComponent
          question={question}
          onChangeQuestion={onChangeQuestion}
          submitQuestion={submitQuestion}
          handleEnter={handleEnter}
        />
      )} */}
    </>
  );
};
