import React, { FC, useCallback, MouseEvent} from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import style from './style.module.less';
import ArrowRightIcon from '../../assets/icons/arrow-right.svg?react';
import BinIcon from '../../assets/icons/bin.svg?react';
import StopIcon from '../../assets/icons/stop.svg?react';
import { MessageRow } from '@law-connect/react-components';
import placeholderImage from '../../assets/profile-no-img.png';
import { useAppDispatch } from '../../redux/hooks';
import { confirm } from '@law-connect/react-components';
import { actions } from '../../redux/slices';
import { toast } from 'react-toastify';

interface Props {
  id: string;
  name: string;
  prematterName: string;
  timestamp: number;
  isNew?: boolean;
  image?: string;
}

export const MessageRowComponent: FC<Props> = (props) => {
  const { id, image, name, prematterName, isNew, timestamp } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const goToMessage = useCallback((e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    navigate(`/lawyer-connection/${id}`);
  }, [id, navigate]);

  const endChat = useCallback(() => {
    // open the link in a new tab
    confirm({
      title: t('connect.end-chat'),
      description: t('connect.end-chat-confirmation'),
      onConfirm: async () => {
        try {
          await dispatch(actions.lawyerConnection.markEnded({ id })).unwrap();
          toast.success(t('connect.end-chat-success'));
        } catch (error) {
          console.error('end chat', error);
          toast.error(t('connect.end-chat-error'));
        }
      },
    });
  }, [dispatch, id, t]);

  const deleteChat = useCallback(async () => {
    confirm({
      title: t('connect.delete-conversation'),
      description: t('connect.delete-conversation-confirmation'),
      onConfirm: async () => {
        try {
          await dispatch(actions.lawyerConnection.markDeleted({ id })).unwrap();
          toast.success(t('connect.delete-conversation-success'));
        } catch (error) {
          console.error('delete chat', error);
          toast.error(t('connect.delete-conversation-error'));
        }
      }
    });
  }, [dispatch, id, t]);

  return (
    <MessageRow
      description={prematterName}
      title={name}
      isNew={isNew}
      timestamp={timestamp}
      onClick={goToMessage}
      Image={
        // image ? (
        // eslint-disable-next-line @stylistic/js/max-len
        <img
          src={image}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = placeholderImage;
          }}
          alt={name}
          className={style.lawyerImage}
        />
        // ) : (
        //   <div className={style.lawyerPlaceholder}>
        //     {name?.length ? name[0] : '?'}
        //   </div>
        // )
      }
      button={{
        label: t('account.view'),
        onClick: goToMessage,
        icon: <ArrowRightIcon />,
      }}
      items={[
        {
          label: t('account.view'),
          icon: <ArrowRightIcon />,
          onClick: goToMessage,
        },
        {
          label: t('connect.end-chat'),
          icon: <StopIcon />,
          onClick: endChat,
        },
        {
          label: t('prematter.delete'),
          icon: <BinIcon />,
          onClick: deleteChat
        },
      ]}
    />
  );
};
