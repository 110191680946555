import i18n from 'i18next';
import { toast } from 'react-toastify';
import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';
import { ClientIntakeForm } from '@law-connect/types';
import { LawyerConnectionPermissions } from '@law-connect/types';
import { actions } from '..';

const STORE = 'lawyerConnection';
const lawyerConnectionThunk = {
  fetchAll: createAsyncThunk(
    `${STORE}/fetchAll`,
    async (_, { rejectWithValue }) => {
      try {
        const response = await api.lawyerConnection.fetchAll();
        return response;
      } catch (error) {
        console.error('fetch connections', error);
        return rejectWithValue(error.message as string);
      }
    }
  ),
  fetchByPrematterId: createAsyncThunk(
    `${STORE}/fetchByPrematterId`,
    async (args: { prematterId: string }, { rejectWithValue }) => {
      try {
        const { prematterId } = args;
        const response = await api.lawyerConnection.fetchByPrematter({
          id: prematterId,
        });
        return response;
      } catch (error) {
        console.error('fetch connections by prematter', error);
        return rejectWithValue(error.message as string);
      }
    }
  ),
  fetchById: createAsyncThunk(
    `${STORE}/fetchById`,
    async (args: { id: string }, { rejectWithValue }) => {
      try {
        const { id } = args;
        const response = await api.lawyerConnection.fetchById({ id });
        return response;
      } catch (error) {
        console.error('fetch connection by id', error);
        return rejectWithValue(error.message as string);
      }
    }
  ),
  fetchMessages: createAsyncThunk(
    `${STORE}/fetchMessages`,
    async (args: { id: string }, { rejectWithValue }) => {
      try {
        const { id } = args;
        const response = await api.lawyerConnection.fetchMessages({ id });
        return response;
      } catch (error) {
        console.error('fetch message by id', error);
        return rejectWithValue(error.message as string);
      }
    }
  ),
  sendMessage: createAsyncThunk(
    `${STORE}/sendMessage`,
    async (
      args: { id: string; message: string; files?: File[] },
      { rejectWithValue }
    ) => {
      try {
        const { id } = args;
        const message = await api.lawyerConnection.postMessage({
          id,
          message: args.message,
          files: args.files,
        });
        return message;
      } catch (error) {
        console.error('send message', error);
        return rejectWithValue(error.message as string);
      }
    }
  ),
  sendFiles: createAsyncThunk(
    `${STORE}/sendFiles`,
    async (args: { id: string; files: File[] }, { rejectWithValue }) => {
      try {
        const { id, files } = args;
        const message = await api.lawyerConnection.postFiles({
          id,
          files,
        });
        return message;
      } catch (error) {
        console.error('send message', error);

        toast.error(i18n.t('toast.file-upload-error').replace('FILE_NAME', ''));
        return rejectWithValue(error.message as string);
      }
    }
  ),
  fetchFiles: createAsyncThunk(
    `${STORE}/fetchFiles`,
    async (args: { id: string }, { rejectWithValue }) => {
      try {
        const { id } = args;
        const files = await api.lawyerConnection.fetchFiles({ id });
        return files;
      } catch (error) {
        console.error('fetch files', error);
        return rejectWithValue(error.message as string);
      }
    }
  ),
  sendPermissionMessage: createAsyncThunk(
    `${STORE}/sendPermissionMessage`,
    async (
      args: {
        id: string;
        permissions: LawyerConnectionPermissions;
        requestMessageId: string;
      },
      { rejectWithValue }
    ) => {
      try {
        const { id } = args;
        const message = await api.lawyerConnection.postPermissionMessage({
          id,
          permissions: args.permissions,
          requestMessageId: args.requestMessageId,
        });
        console.log('send permission message', message);
        return message;
      } catch (error) {
        console.error('send message', error);
        return rejectWithValue(error.message as string);
      }
    }
  ),
  updateReadAt: createAsyncThunk(
    `${STORE}/updateReadAt`,
    async (args: { id: string; messageId?: string }, { rejectWithValue }) => {
      try {
        const { id } = args;
        await api.lawyerConnection.updateReadAt({
          id,
          messageId: args.messageId,
        });
        return Date.now();
      } catch (error) {
        console.error('update read at', error);
        return rejectWithValue(error.message as string);
      }
    }
  ),
  fetchClientIntakeForm: createAsyncThunk(
    `${STORE}/fetchClientIntakeForm`,
    async (args: { connectionId: string }, { rejectWithValue }) => {
      try {
        const { connectionId } = args;
        const response = await api.lawyerConnection.fetchClientIntakeForm({
          connectionId,
        });
        return response;
      } catch (error) {
        console.error('fetch client intake form', error);
        return rejectWithValue(error.message as string);
      }
    }
  ),
  updateClientIntakeForm: createAsyncThunk(
    `${STORE}/updateClientIntakeForm`,
    async (
      args: { connectionId: string; form: ClientIntakeForm },
      { rejectWithValue }
    ) => {
      try {
        const { connectionId, form } = args;
        await api.lawyerConnection.updateClientIntakeForm({
          connectionId,
          form,
        });
        return form;
      } catch (error) {
        console.error('update client intake form', error);
        return rejectWithValue(error.message as string);
      }
    }
  ),
  markClientIntakeFormAsCompleted: createAsyncThunk(
    `${STORE}/markAsCompleted`,
    async (args: { connectionId: string }, { rejectWithValue }) => {
      try {
        const { connectionId } = args;
        const { form, message } =
          await api.lawyerConnection.markClientIntakeFormAsCompleted({
            connectionId,
          });
        return { form, message };
      } catch (error) {
        console.error('mark as completed', error);
        return rejectWithValue(error.message as string);
      }
    }
  ),
  markEnded: createAsyncThunk(
    `${STORE}/mark-ended`,
    async (args: { id: string }, { rejectWithValue, dispatch }) => {
      try {
        const { id } = args;
        await api.lawyerConnection.markEnded({ id });
        dispatch(actions.lawyerConnection.deleteChatMessage({ id }));
        // on success we also want to add deleted message
        return id;
      } catch (error) {
        console.error('end chat', error);
        return rejectWithValue(error.message as string);
      }
    }
  ),
  markDeleted: createAsyncThunk(
    `${STORE}/mark-deleted`,
    async (args: { id: string }, { rejectWithValue, dispatch }) => {
      try {
        const { id } = args;
        await api.lawyerConnection.markDeleted({ id });
        dispatch(actions.lawyerConnection.deleteChatMessage({ id }));
        return id;
      } catch (error) {
        console.error('end chat', error);
        return rejectWithValue(error.message as string);
      }
    }
  ),
  deleteChatMessage: createAsyncThunk(
    `${STORE}/delete-chat-message`,
    async (args: { id: string }, { rejectWithValue }) => {
      try {
        const { id } = args;
        const message = await api.lawyerConnection.postDeleteMessage({ id });
        return message;
      } catch (error) {
        console.error('delete chat message', error);
        return rejectWithValue(error.message as string);
      }
    }
  ),
};

export default lawyerConnectionThunk;
