import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useKindeAuth } from '@kinde-oss/kinde-auth-react';
import style from './style.module.less';
import { Wrapper } from '../../components/wrapper';
import { Header } from '../../components/header';
import { Footer } from '../../components/footer';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../components/button';
// eslint-disable-next-line @stylistic/js/max-len
import { useScreenSize } from '../../hooks/use-is-mobile';
import { HomeHero } from './hero';
import { useHomeExamples } from '../../hooks/use-home-examples';
import RefreshIcon from '../../assets/icons/refresh.svg?react';
import { HomeDescriptionComponent } from './description';
import { Helmet } from 'react-helmet';
import { lawyerImages, LawyerImage } from './lawyer-images';
import { EmailIssuesComponent } from './description/email-issues';
import { RegisterAccountComponent } from './description/register-account';
import { HomeHowItWorks } from './how-it-works';
import { HomeSecurityComponent } from './security';
import { HomeBenefitsComponent } from './benefits';
import { OldLawConnectBanner } from './old-law-connect';

const GENERATE_AMOUNT = 4;

const generateLawyerImages = (): LawyerImage[] => {
  // find random image from the list
  const leftFacingImages = lawyerImages.filter((lawyer) => lawyer.left);
  const leftFacing =
    leftFacingImages[Math.floor(Math.random() * leftFacingImages.length)];
  const rightFacingImages = lawyerImages
    .filter((lawyer) => !lawyer.left)
    .filter((lawyer) => lawyer.female !== leftFacing.female);
  const rightFacing =
    rightFacingImages[Math.floor(Math.random() * rightFacingImages.length)];

  return [leftFacing, rightFacing];
};

interface Props {}
export const Home: React.FC<Props> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { register, isAuthenticated } = useKindeAuth();
  const screenType = useScreenSize();
  const homeExamples = useHomeExamples();

  const [lawyerImages] = useState(generateLawyerImages);
  const [examples, setExamples] = useState(
    [...homeExamples].sort(() => Math.random() - 0.5)
  );
  const [page, setPage] = useState(0);

  useEffect(() => {
    setExamples([...homeExamples].sort(() => Math.random() - 0.5));
  }, [homeExamples]);

  const onCaseClick = useCallback(
    (description?: string | null) => {
      navigate('/chat', {
        state: { newCase: true, message: description ?? undefined },
      });
    },
    [navigate]
  );

  const onAuthClick = useCallback(() => {
    if (isAuthenticated) {
      navigate('/chat', {
        state: { newCase: true, message: undefined },
      });
    } else {
      register();
    }
  }, [isAuthenticated, navigate, register]);

  const regenerate = useCallback(() => {
    if (page < Math.ceil(examples.length / GENERATE_AMOUNT) - 1) {
      setPage(page + 1);
    } else {
      setPage(0);
    }
  }, [examples.length, page]);

  const lawyerLeft = useMemo(() => lawyerImages[0], [lawyerImages]);
  const lawyerRight = useMemo(() => lawyerImages[1], [lawyerImages]);

  return (
    <>
      <Helmet>
        <title>{t('pages.home')}</title>
      </Helmet>
      {/* <OldLawConnectBanner /> */}
      <Header />
      <Wrapper className={style.wrapper} contentClassName={style.content}>
        <HomeHero lawyerLeft={lawyerLeft} lawyerRight={lawyerRight} />
        <HomeHowItWorks />
        <EmailIssuesComponent lawyerImage={lawyerLeft} />
        <div className={style.examplesSection}>
          <div className={style.title}>{t('home.example-title')}</div>
          <div className={style.examplesContainer}>
            {examples
              .slice(page * GENERATE_AMOUNT, (page + 1) * GENERATE_AMOUNT)
              .map((example) => (
                <div
                  className={style.row}
                  key={example.description}
                  onClick={() => onCaseClick(example.description)}
                >
                  <div className={style.iconContainer}>
                    <example.icon className={style.icon} />
                  </div>
                  <div className={style.textWrapper}>
                    <div className={style.exampleTitle}>{example.title}</div>
                    <div className={style.exampleDescription}>
                      {example.description}
                    </div>
                  </div>
                  <div className={style.actionContainer}>
                    <Button
                      label={t('home.try-it-out')}
                      className={style.actionButton}
                      labelClassName={style.buttonLabel}
                      iconClassName={style.buttonIcon}
                      onClick={() => {}}
                    />
                  </div>
                </div>
              ))}
            <div className={style.tryMoreContainer}>
              <button className={style.tryMoreButton} onClick={regenerate}>
                <span>{t('home.try-some-more')}</span>
                <RefreshIcon className={style.refreshIcon} />
              </button>
            </div>
          </div>
        </div>

        <HomeBenefitsComponent lawyerImage={lawyerLeft}/>
        <HomeSecurityComponent />
        <RegisterAccountComponent lawyerImage={lawyerRight} />
        <div className={`${style.section} ${style.center}` }>
          <div className={style.disclaimerTextWrapper}>
            <div
              className={style.disclaimer}
              dangerouslySetInnerHTML={{ __html: t('auth.disclaimer') }}
            />
            <div
              className={style.disclaimer}
              dangerouslySetInnerHTML={{ __html: t('auth.disclaimer-lawyer') }}
            />
          </div>
        </div>
      </Wrapper>
      <Footer />
    </>
  );
};
