import React, { FC, useMemo } from 'react';
import style from './style.module.less';
import { ButtonTitle } from '../button-title';

interface Description {
  title?: string;
  context: string;
}

interface Props {
  iconTitle: string;
  title: string;
  descriptions: Description[];
  right?: boolean;
  image?: string;
}

export const AboutCardComponent: FC<Props> = (props) => {
  const { iconTitle, title, descriptions, right } = props;

  return (
    <div
      className={`${style.container} ${
        right == null ? style.center : style.grid
      } ${right == null ? '' : right ? style.right : style.left}`}
    >
      <div className={style.positionWrapper}>
        <ButtonTitle title={iconTitle} className={style.iconTitle} />
        <div className={style.title}>{title}</div>
        <div className={style.descriptionsContainer}>
          {descriptions.map((description, index) => (
            <div key={index} className={style.description}>
              {description.title && (
                <div className={style.cardTitle}>{description.title}</div>
              )}
              <div
                className={style.context}
                dangerouslySetInnerHTML={{ __html: description.context }}
              />
            </div>
          ))}
        </div>
        <img src={props.image} alt='scale' className={style.aboutImage} />
      </div>
    </div>
  );
};
